import React from 'react'
import { useParams } from 'react-router-dom'
import { ArrowLeft, BoxArrowUpRight } from 'react-bootstrap-icons'
import { Button } from '../../../reusableElements/button/Button'
import { UpdateCreateForm } from './elements/updateCreateForm/UpdateCreateForm'
import c from './articleEditPage.module.scss'
import { Preloader } from '../../../reusableElements/preloader/Preloader'
import { useDispatch, useSelector } from 'react-redux'
import { articlesAdminActs } from '../../../../store/reducers/articles/articlesAdminSlice'
import { cutText } from '../../../../helpers/cutText'

// если такой новости нет в бд - ошибка (не 404, а ошибка)
const EditArtPage = () => {
   const { id: artId } = useParams()

   const dispatch = useDispatch()

   const {
      artFullView,
      loadProg: { isLoadingArtFullView, isLoadingArtUpdate },
      errs: { errOnGetArtFullView, errOnArtUpdate },
   } = useSelector(state => state.articlesAdmin)

   React.useEffect(() => {
      dispatch(articlesAdminActs.setErrOnArtUpdate(null)) // зануляем эту ошибку при первой загр страницы
      dispatch(articlesAdminActs.getFullView(artId))
   }, [dispatch])

   const onSubmitFormHandler = artData => {
      dispatch(articlesAdminActs.startArtUpdate({ ...artFullView, ...artData }))
   }

   return (
      <div className={`section ${c.adminSection}`}>
         <div className={`adminArticle__container ${c.adminArticleWr}`}>
            <Button
               Icon={BoxArrowUpRight}
               extraClassName={c.goOnSiteBtn}
               title='Открыть на сайте'
               tag='link'
               linkPath={`/news/${artId}`}
               openLinkInNewWindow={true}
            />
            <div className={c.titleBtnWr}>
               <Button
                  name='К списку новостей'
                  Icon={ArrowLeft}
                  extraClassName={c.goBackBtn}
                  title='К списку новостей'
                  tag='link'
                  linkPath='/admin/articles'
                  isDisabled={false}
                  isLoading={false}
                  preloaderClr='#fff'
               />
               <h2 className={c.pageTitle}>Редактирование новости</h2>
            </div>
            {isLoadingArtFullView ? (
               <Preloader color={'#183864'} size={130} minHeight='60vh' />
            ) : errOnGetArtFullView ? (
               <div className={c.lettWr}>
                  <span className={`${c.err} err`}>{cutText(errOnGetArtFullView, 100)}</span>
               </div>
            ) : (
               <UpdateCreateForm
                  initialData={artFullView}
                  isInProgressSubmit={isLoadingArtUpdate.includes(artFullView.id)}
                  errOnSubmit={errOnArtUpdate}
                  onSubmitHandler={onSubmitFormHandler}
                  haveResetBtn={false}
               />
            )}
         </div>
      </div>
   )
}

export { EditArtPage }
