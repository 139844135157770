import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { articlesAppReducer } from './reducers/articles/articlesAppSlice'
import { articlesAdminReducer } from './reducers/articles/articlesAdminSlice'
import { rootSaga } from './sagas'
import { authReducer } from './reducers/articles/authAppSlice'

const sagaMiddleware = createSagaMiddleware()

// каждый слайс отвечает за ту или иную часть приложения
export const store = configureStore({
   reducer: {
      articlesApp: articlesAppReducer,
      articlesAdmin: articlesAdminReducer,
      auth: authReducer,
   },
   middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false, serializableCheck: { ignoredActionPaths: ['payload.cover'] } }).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)
/**
 * Получить порцию новостей
 * Получить 1 новость целиком
 *
 * Сделать 1 новость видимой-невидимой
 * Удалить 1 новость
 * Сделать ресорт порции новостей
 *
 *
 ** Поля в сторе:
 *
 ** articlesPortion
 ** articleFullView
 ** totalArtsCount
 ** currPage
 ** portionSize
 ** customOffset
 **
 ** isLoadingArticlesPortion
 ** isLoadingArticleFullView
 ** isLoadingArticleUpdate (загрузка обновленной информации для новости или создание новой новости) (храниться в формате: [id обновляющихся новостей])
 ** isLoadingArticleCreate (загрузка данных для создания новой новости)
 ** isLoadingArticleDelete (храниться в формате: [id удаляемых новостей])
 ** isLoadingArticlesResort
 **
 *? errs:
 ** errOnGetArticlesPortion
 ** errOnGetArticleFullView
 ** errOnUpdateArticle
 ** errOnCreateArticle
 ** errOnDeleteArticle
 ** errOnResortArticles
 *
 ** login
 ** errOnLogIn
 ** errOnCheckAuth
 *
 */
