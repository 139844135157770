import React from 'react'
import { ArrowRepeat, CheckCircle, ExclamationCircle, Save } from 'react-bootstrap-icons'
import { CoverBlock } from '../coverBlock/CoverBlock'
import { Button } from '../../../../../reusableElements/button/Button'
import { TextEditor } from '../textEditor/TextEditor'
import { cutText } from '../../../../../../helpers/cutText'
import c from './updateCreateForm.module.scss'

//! Надо занулять ошибки 

const UpdateCreateForm = ({ initialData, isInProgressSubmit, errOnSubmit, onSubmitHandler, haveResetBtn }) => {
   const today = { y: new Date().getFullYear(), m: String(new Date().getMonth() + 1), d: String(new Date().getDate()) }
   const dateStr = `${today.y}-${today.m.length === 1 ? '0' + today.m : today.m}-${today.d.length === 1 ? '0' + today.d : today.d}`

   const { title, cover, date, content, published } = initialData
   const [titleInInp, setTitleInInp] = React.useState(title || '')
   const [coverInInp, setCoverInInp] = React.useState(cover || null)
   const [dateInInp, setDateInInp] = React.useState(date || dateStr)
   const [publishedInInp, setPublishedInInp] = React.useState(!!published)

   const editorRef = React.useRef(null)

   const isValidTitle = !!titleInInp
   const isValidDate = !!dateInInp

   const isSomeFieldInvalid = !isValidTitle || !isValidDate

   const [countSubmits, setCountSubmits] = React.useState(0)

   const onSubmitHandlerWr = e => {
      e.preventDefault()
      if (!editorRef.current) return

      setCountSubmits(countSubmits + 1)

      editorRef.current.uploadImages().then(result => {
         onSubmitHandler({
            title: titleInInp,
            cover: coverInInp,
            date: dateInInp,
            content: editorRef.current.getContent(),
            published: publishedInInp ? 1 : 0,
         })
      })
   }

   const resetFormHandler = e => {
      setTitleInInp('')
      setCoverInInp(null)
      setDateInInp(dateStr)
      setPublishedInInp(true)
      editorRef?.current?.setContent('')
   }

   return (
      <>
         {haveResetBtn && (
            <Button
               type='button'
               Icon={ArrowRepeat}
               extraClassName={c.resetFormBtn}
               title='Очистить поля формы'
               name='Очистить'
               onClickHandler={resetFormHandler}
            />
         )}
         <form onSubmit={onSubmitHandlerWr}>
            <div>
               <div className={`${c.inputsWr} ${c.titleInpWr}`}>
                  <div className={`${c.inputGroup} ${!isValidTitle ? c.withError : ''}`}>
                     <label htmlFor='title'>Заголовок</label>
                     <input type='text' id='title' value={titleInInp} onInput={e => setTitleInInp(e.currentTarget.value)} />
                     <span className={c.spanErr}> Поле обязательно для заполнения</span>
                  </div>
               </div>

               <div className={c.coverVisDateWr}>
                  <CoverBlock photoValue={coverInInp} onChangePhotoValueHandler={setCoverInInp} />

                  <div className={c.visDateWr}>
                     <div className={`${c.inputGroup} ${!isValidDate ? c.withError : ''}`}>
                        <label htmlFor='date'>Дата публикации</label>
                        <input type='date' id='date' value={dateInInp} onChange={e => setDateInInp(e.currentTarget.value)} />

                        <span className={c.spanErr}> Поле обязательно для заполнения</span>
                     </div>

                     <div className={`${c.inputGroup}`}>
                        <label htmlFor='visibility'>Видимость</label>
                        <select onChange={e => setPublishedInInp(e.currentTarget.value)} value={publishedInInp} id='visibility'>
                           <option value={true}>Опубликована</option>
                           <option value={false}>Скрыта</option>
                        </select>
                     </div>
                  </div>
               </div>

               <div className={`${c.editorWr} ${c.inputGroup}`}>
                  <TextEditor editorRef={editorRef} initialValue={content} editorId={'content'} editorLabel={'Контент'} />
               </div>

               <Button
                  type='submit'
                  name='Сохранить'
                  title='Сохранить'
                  extraClassName={c.submitBtn}
                  isDisabled={isSomeFieldInvalid}
                  isLoading={isInProgressSubmit}
                  Icon={Save}
               />

               <div className={`${c.succOrErr} ${countSubmits && !isInProgressSubmit ? c.visible : ''} ${errOnSubmit ? c.err : c.succ}`}>
                  {errOnSubmit ? (
                     <>
                        <ExclamationCircle className='err' />
                        <span className='err'>{cutText(errOnSubmit, 70)}</span>
                     </>
                  ) : (
                     <>
                        <CheckCircle className='succ' />
                        <span className='succ'>Сохранено</span>
                     </>
                  )}
               </div>
            </div>
         </form>
      </>
   )
}

export { UpdateCreateForm }
