import React from 'react'
import { Reorder } from 'framer-motion'
import { PlusLg } from 'react-bootstrap-icons'
import { Paginator } from '../../../reusableElements/paginator/Paginator'
import { Button } from '../../../reusableElements/button/Button'
// import coverTmp from '../../../../assets/images/news/1.webp'
import { ArticlePreview } from './ArticlePreview'
import { Preloader } from '../../../reusableElements/preloader/Preloader'
import c from './articlesList.module.scss'
import { ModalOnDelete } from '../../../reusableElements/modalOnDelete/ModalOnDelete'
import { onOpenModal, onCloseModal } from '../../../../helpers/forOpenModalOverflowHandler'
import { cutText } from '../../../../helpers/cutText'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { articlesAdminActs } from '../../../../store/reducers/articles/articlesAdminSlice'

const PORTION_SIZE = 11
const getValidPageNum = page => (page && +page ? +page : 1)
const getCustomOffset = (pageNum, portionSize = PORTION_SIZE) => {
   // в админке запрашиваем по 11 новостей
   // 1ая страница - последние 11 OFFSET 0    c 1 по 11     offset 0
   // 2ая страница - следующие 11 OFFSET 10   с 11 по 21    offset 10
   // 3ая страница - следующие 11 OFFSET 20   с 21 по 31    offset 20
   //                                         c 31 по 41    offset 30
   //                  ...
   return (pageNum - 1) * (portionSize - 1)
}

const ArticlesListPage = () => {
   const dispatch = useDispatch()

   const {
      artsPortion,
      totalArtsCount,
      currPage,
      portionSize,
      loadProg: { isLoadingArtsPortion, isLoadingArtsResort, isLoadingArtUpdate, isLoadingArtDelete },
      errs: { errOnGetArtsPortion, errOnArtsResort, errOnArtUpdate, errOnArtDelete },
   } = useSelector(state => state.articlesAdmin)

   //! Изменение видимости новости
   const onClickChangePublBtnHandler = artData => dispatch(articlesAdminActs.startArtUpdate(artData))

   //! Удаление новости
   const [isOpenModalOnDelete, setIsOpenModalOnDelete] = React.useState(false)
   const [dataForModalOnDelete, setDataForModalOnDelete] = React.useState({ title: null, id: null })

   const onClickDeleteBtnHandler = data => {
      onOpenModal()
      setIsOpenModalOnDelete(true)
      setDataForModalOnDelete(data)
      dispatch(articlesAdminActs.setErrOnArtDelete(null))
   }

   const closeModalOnDeleteHandler = () => {
      onCloseModal()
      setIsOpenModalOnDelete(false)
   }

   // удаляем новость, id ее берем из dataForModalOnDelete.id
   const deleteArtHandler = () => dispatch(articlesAdminActs.startArtDelete(dataForModalOnDelete.id))

   //! Пагинация

   const setNullErrs = () => {
      //! Надо занулять ошибки при перелистывании страниц
      dispatch(articlesAdminActs.setErrOnArtsResort(null))
      dispatch(articlesAdminActs.setErrOnGetPortion(null))
      dispatch(articlesAdminActs.setErrOnArtUpdate(null))
      dispatch(articlesAdminActs.setErrOnArtDelete(null))
   }

   const [searchParams, setSearchParams] = useSearchParams()
   React.useEffect(() => {
      const activePageFromURL = getValidPageNum(searchParams.get('page'))
      dispatch(articlesAdminActs.setCurrPage(activePageFromURL))
      dispatch(articlesAdminActs.setPortionSize(PORTION_SIZE))
      dispatch(articlesAdminActs.setCustomOffset(getCustomOffset(activePageFromURL)))
   }, [dispatch])

   // обновление параметров поисковой строки
   React.useEffect(() => {
      if (currPage !== null) setSearchParams({ page: String(currPage) })
      setNullErrs()
   }, [currPage])

   const onChangePageFunc = page => {
      page = getValidPageNum(page)
      dispatch(articlesAdminActs.setCurrPage(page))
      dispatch(articlesAdminActs.setCustomOffset(getCustomOffset(page)))
   }

   //! Пересортировка новостей

   const [artsLocalState, setArtsLocalState] = React.useState([])

   React.useEffect(() => {
      setArtsLocalState(JSON.parse(JSON.stringify(artsPortion)))
   }, [artsPortion])

   const handleReorder = reorderedArts => {
      const newAndOldNums = {} // id: {old: ..., new: ... }

      artsLocalState.forEach((a, idx) => (newAndOldNums[a.id] = { old: idx }))
      reorderedArts.forEach((a, idx) => (newAndOldNums[a.id].new = idx))

      for (const artIdx in newAndOldNums) {
         if (newAndOldNums[artIdx].old === newAndOldNums[artIdx].new) delete newAndOldNums[artIdx]
      }

      const changedArts = reorderedArts.filter(a => a.id in newAndOldNums)

      const f = changedArts[0].sort_num
      const s = changedArts[1].sort_num

      changedArts[0].sort_num = s
      changedArts[1].sort_num = f

      setArtsLocalState(reorderedArts)
   }

   const onPointerUp = e => {
      const a1 = artsLocalState
      const a2 = artsPortion
      if (!(a1.length === a2.length && a1.every((a, index) => a.id === a2[index].id && a.sort_num === a2[index].sort_num))) {
         dispatch(articlesAdminActs.resortArts(artsLocalState))
      }
   }

   //? Показ маленькой ошибки снизу

   const [lastEventErr, setLastEventErr] = React.useState(null)

   React.useEffect(() => {
      setLastEventErr(errOnArtUpdate)
   }, [errOnArtUpdate])

   React.useEffect(() => {
      setLastEventErr(errOnArtDelete)
   }, [errOnArtDelete])

   return (
      <>
         <div className={`section ${c.adminSection}`}>
            <div className={`adminNews__container ${c.adminNewsWr}`}>
               <div className={c.titleCreateBtnWr}>
                  <h2 className={c.pageTitle}>Новости</h2>
                  <Button
                     type='submit'
                     name='Создать'
                     Icon={PlusLg}
                     extraClassName={c.createBtn}
                     title='Создать'
                     tag='link'
                     linkPath='create'
                     isDisabled={false}
                     isLoading={false}
                     preloaderClr='#fff'
                     onClickHandler={() => {}}
                  />
               </div>

               {isLoadingArtsPortion ? (
                  <Preloader color={'#183864'} size={130} minHeight='90vh' />
               ) : errOnGetArtsPortion ? (
                  <div className={c.lettWr}>
                     <span className={`${c.err} err`}>{cutText(errOnGetArtsPortion, 100)}</span>
                  </div>
               ) : (
                  <>
                     {!artsPortion || artsPortion.length === 0 ? (
                        <div className={c.lettWr}>
                           <span className={`${c.noArtsLett}`}>Новостей не найдено</span>
                        </div>
                     ) : (
                        <>
                           <div className={`${c.smallCommonErr} ${lastEventErr ? c.visible : ''} err`}>
                              {cutText(lastEventErr || '', 100)}
                           </div>

                           <div className={c.articlesContainer}>
                              <div className={`${c.tablesTitles} ${c.articleRow}`}>
                                 <div className={c.dragEl}></div>
                                 <div className={c.title}>
                                    <p>Заголовок</p>
                                 </div>
                                 <div className={c.published}>
                                    <p>Видимость</p>
                                 </div>
                                 <div className={c.edit}>
                                    <p>Редакт.</p>
                                 </div>
                                 <div className={c.trash}>
                                    <p>Удалить</p>
                                 </div>
                              </div>

                              <Reorder.Group
                                 as='ul'
                                 axis='y'
                                 values={artsLocalState}
                                 onReorder={handleReorder}
                                 className={`${c.draggableArtsWr} ${isLoadingArtsResort || errOnArtsResort ? c.tmpDisabled : ''}`}>
                                 {artsLocalState.map(art => (
                                    <Reorder.Item
                                       transition={{ duration: 0 }}
                                       key={`${art.id}`}
                                       className={c.articleRow}
                                       value={art}
                                       onPointerUp={onPointerUp}
                                       whileDrag={{
                                          boxShadow: '4px 4px 29px 0px rgba(34, 60, 80, 0.2)',
                                          backgroundColor: '#FFCC8D',
                                          scale: 1.01,
                                       }}>
                                       <ArticlePreview
                                          artData={art}
                                          onClickDeleteBtnHandler={onClickDeleteBtnHandler}
                                          onClickChangePublBtnHandler={onClickChangePublBtnHandler}
                                          isInProgressChangePubl={isLoadingArtUpdate.includes(art.id)}
                                          isInProgressDeleting={isLoadingArtDelete.includes(art.id)}
                                       />
                                    </Reorder.Item>
                                 ))}

                                 {isLoadingArtsResort ? (
                                    <div className={c.preloaderWr}>
                                       <Preloader color={'#183864'} size={130} minHeight='100%' />
                                    </div>
                                 ) : (
                                    <span className={`${c.reordErr} err`}>{cutText(errOnArtsResort || '', 100)}</span>
                                 )}
                              </Reorder.Group>
                           </div>
                        </>
                     )}
                  </>
               )}

               <div className={c.paginationBlock}>
                  <Paginator
                     onPageChangeHandler={onChangePageFunc}
                     totalRecordsCount={totalArtsCount}
                     pageLimit={portionSize}
                     baseURI={'/admin/articles?'}
                     activePage={currPage}
                  />
               </div>
            </div>
         </div>

         <ModalOnDelete
            isOpen={isOpenModalOnDelete}
            closeModalHandler={closeModalOnDeleteHandler}
            deleteItemHandler={deleteArtHandler}
            lettPartBefore='Удалить безвозвратно новость '
            lettPartItemInfo={`«${cutText(dataForModalOnDelete.title || '', 200)}»`}
            lettPartAfter='?'
            isAlreadyDeleted={!!!artsPortion.find(a => a.id === dataForModalOnDelete.id)}
            isDelettingInProgress={isLoadingArtDelete.includes(dataForModalOnDelete.id)}
            errOnDelete={errOnArtDelete}
         />
      </>
   )
}

export { ArticlesListPage }
