import { TelephoneFill, EnvelopeAt, GeoAltFill } from 'react-bootstrap-icons'
import bgWh from '../../../../assets/images/common/bgWhite.webp'
import c from './contacts.module.scss'
import React from 'react'



const contactsData = {
    address: '450071, Республика Башкортостан, город Уфа, ул. Рязанская, д. 10, офис 207',
    phone: '+7 (347) 246-84-04',
    email: 'zakaz@ekm-trade.ru'
}


const Contacts = ({hash}) => {
    const contRef = React.useRef()
    if (hash == '#contacts') {
        contRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
    }

    return (
        <section id='contacts' ref={contRef} className={`${c.contacts} section`} style={{
            backgroundColor: '#000',
            backgroundImage: `url('${bgWh}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover'
        }}>
            <div className={`contacts__container ${c.container}`}>
                <h2>Контакты</h2>
                <div className={c.content} >
                    <ul className={c.contactsList}>

                        <li className={c.contactItem}>
                            <span className={c.lett}>Телефон:</span>
                            <a className={c.info} href={`tel:${contactsData.phone.replace(/\D/g, '')}`}>
                                <TelephoneFill />
                                {contactsData.phone}
                            </a>
                        </li>

                        <li className={c.contactItem}>
                            <span className={c.lett}>Email:</span>
                            <a className={c.info} href={`mailto:${contactsData.email}`}>
                                <EnvelopeAt />
                                {contactsData.email}
                            </a>
                        </li>

                        <li className={c.contactItem}>
                            <span className={c.lett}>Адрес:</span>
                            <span className={c.info}>
                                <GeoAltFill />
                                {contactsData.address}
                            </span>
                        </li>

                    </ul>

                    <div className={c.mapWr}>
                        <div className={c.mapWr_inner}>
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aec2283d659b37709718d04788b8594c3010cfb43090eec494b8398a5419f4e19&amp;source=constructor" width="100%" height='100%' frameBorder="0"></iframe>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export { Contacts }