import React from 'react'
import { Link as LinkRR, useParams } from 'react-router-dom'
import c from './articles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { articlesAppActs } from '../../../store/reducers/articles/articlesAppSlice'
import { Preloader } from '../../reusableElements/preloader/Preloader'
import { cutText } from '../../../helpers/cutText'
import { getPhotoPath } from '../../../helpers/getPhotoPath'

const ArticlePage = () => {
   const { artId } = useParams()

   const dispatch = useDispatch()

   React.useEffect(() => {
      dispatch(articlesAppActs.getFullView(artId))
   }, [])

   const {
      artFullView,
      loadProg: { isLoadingArtFullView },
      errs: { errOnGetArtFullView },
   } = useSelector(state => state.articlesApp)

   return (
      <div className={`article__container ${c.container}`}>
         <ul className={c.breadcrumbs}>
            <li>
               <LinkRR to='/'>Главная</LinkRR>
            </li>
            <li>
               <LinkRR to='/news?page=1'>Новости</LinkRR>
            </li>

            {isLoadingArtFullView || errOnGetArtFullView ? (
               ''
            ) : (
               <li>
                  <span>{artFullView.title}</span>
               </li>
            )}
         </ul>

         {isLoadingArtFullView ? (
            <Preloader color={'#183864'} size={130} minHeight='60vh' />
         ) : errOnGetArtFullView ? (
            <div className={c.lettWr}>
               <span className={`${c.err} err`}>{cutText(errOnGetArtFullView, 100)}</span>
            </div>
         ) : !artFullView.published ? (
            <div className={c.lettWr}>
               <span className={`${c.err} err`}>Resource not published</span>
            </div>
         ) : (
            <div className={c.articlePageContent}>
               <div className={c.coverWr}>
                  <img src={getPhotoPath(artFullView.cover)} alt={artFullView.title} />
               </div>

               <p className={c.date}>{artFullView.date}</p>

               <h1 className={c.title}>{artFullView.title}</h1>

               <div className={c.artText} dangerouslySetInnerHTML={{ __html: artFullView.content }} />
            </div>
         )}
      </div>
   )
}

export { ArticlePage }
