import { call, put, select, takeLeading } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'
import { articlesAdminActs, START_ARTICLE_UPDATE_ADMIN } from '../../../reducers/articles/articlesAdminSlice'

const sagaWorkerUpdateArt = function* (action) {
   const art = action.payload

   yield put(articlesAdminActs.setErrOnArtUpdate(null))
   yield put(articlesAdminActs.setLoadingArtUpdate(art.id))
   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.updateArticle, art)
      if (statusCode === 200) {
         const { artsPortion } = yield select(state => state.articlesAdmin)
         const editedArtsPortion = artsPortion.map(a => (a.id === data.id ? data : a))
         yield put(articlesAdminActs.setPortion(editedArtsPortion))
         yield put(articlesAdminActs.setFullView(data))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAdminActs.setErrOnArtUpdate(getErrMessage(err)))
   }
   yield put(articlesAdminActs.setEndLoadingArtUpdate(art.id))
}

export const sagaWatcherUpdateArt = function* () {
   yield takeLeading(START_ARTICLE_UPDATE_ADMIN, sagaWorkerUpdateArt)
}
