import axios from 'axios'
import { createArtFormData, createImgFormData } from './helpers'

const instanceCommon = axios.create({
   withCredentials: true,
   baseURL: process.env.REACT_APP_API_BASE_URL,
})

const articlesFetchingAPI = {
   async getArticlesPortion(page, count, customoffset = 0, published = 'all') {
      return instanceCommon
         .get(`articles?page=${page}&count=${count}&customoffset=${customoffset}&published=${published}`)
         .then(response => response.data)
   },

   async getOneArticle(id) {
      return instanceCommon.get(`articles/${id}`).then(response => response.data)
   },

   async resortArticles(data) {
      return instanceCommon.put(`articles`, data).then(response => response.data)
   },

   async updateArticle(artData) {
      const formData = createArtFormData(artData)

      return instanceCommon
         .post(`articles/${artData.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
         .then(response => response.data)
   },

   async createArticle(artData) {
      const formData = createArtFormData(artData)

      return instanceCommon
         .post(`articles`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
         .then(response => response.data)
   },

   async deleteArticle(id) {
      return instanceCommon.delete(`articles/${id}`).then(response => response.data)
   },
}

const textEditorFetchingAPI = {
   // null | string | Blob
   async saveImg(img, progressCallback) {
      const formData = createImgFormData(img)
      return instanceCommon
         .post(`save-file-from-text-editor`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: ({ loaded, total }) => {
               progressCallback(Math.round((loaded * 100) / total))
            },
         })
         .then(response => response.data)
   },
}

const authFetchingAPI = {
   async login(login, password) {
      return instanceCommon.post(`login`, { login, password }).then(response => response.data)
   },

   async checkAuth() {
      return instanceCommon.get(`login/check`).then(response => response.data)
   },
}

export { articlesFetchingAPI, authFetchingAPI, textEditorFetchingAPI }
