import shortid from 'shortid'
import c from './articles.module.scss'
import { ArticlePreview } from './ArticlePreview'
import { useSearchParams } from 'react-router-dom'
import { Paginator } from '../../reusableElements/paginator/Paginator'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Preloader } from '../../reusableElements/preloader/Preloader'
import { cutText } from '../../../helpers/cutText'
import { articlesAppActs } from '../../../store/reducers/articles/articlesAppSlice'

const PORTION_SIZE = 8
const getCustomOffset = (pageNum, portionSize = PORTION_SIZE) => (pageNum - 1) * portionSize

const getValidPageNum = page => (page && +page ? +page : 1)

const ArticlesPage = () => {
   const dispatch = useDispatch()

   const {
      artsPortion,
      totalArtsCount,
      currPage,
      portionSize,
      loadProg: { isLoadingArtsPortion },
      errs: { errOnGetArtsPortion },
   } = useSelector(state => state.articlesApp)

   const [searchParams, setSearchParams] = useSearchParams()
   React.useEffect(() => {
      const activePageFromURL = getValidPageNum(searchParams.get('page'))
      dispatch(articlesAppActs.setCurrPage(activePageFromURL))
      dispatch(articlesAppActs.setPortionSize(PORTION_SIZE))
      dispatch(articlesAppActs.setCustomOffset(getCustomOffset(activePageFromURL)))
   }, [])

   // обновление параметров поисковой строки
   React.useEffect(() => {
      if (currPage !== null) setSearchParams({ page: String(currPage) })
   }, [currPage])

   const onChangePageFunc = page => {
      page = getValidPageNum(page)
      dispatch(articlesAppActs.setCurrPage(page))
      dispatch(articlesAppActs.setCustomOffset(getCustomOffset(page)))
   }

   return (
      <div className={`articles__container ${c.container}`}>
         <h1 className={c.title}>Новости</h1>

         {isLoadingArtsPortion ? (
            <Preloader color={'#183864'} size={130} minHeight='60vh' />
         ) : errOnGetArtsPortion ? (
            <div className={c.lettWr}>
               <span className={`${c.err} err`}>{cutText(errOnGetArtsPortion, 100)}</span>
            </div>
         ) : (
            <>
               {!artsPortion || artsPortion.length === 0 ? (
                  <div className={c.lettWr}>
                     <span className={`${c.noArtsLett}`}>Новостей не найдено</span>
                  </div>
               ) : (
                  <div className={c.articles}>
                     {artsPortion.map(a => (
                        <ArticlePreview key={a.id} title={a.title} cover={a.cover} date={a.date} link={a.id} />
                     ))}
                  </div>
               )}
               <div className={c.paginationBlock}>
                  <Paginator
                     onPageChangeHandler={onChangePageFunc}
                     totalRecordsCount={totalArtsCount}
                     pageLimit={portionSize}
                     baseURI={'/news?'}
                     activePage={currPage}
                  />
               </div>
            </>
         )}
      </div>
   )
}

export { ArticlesPage }
