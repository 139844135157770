import React from 'react'
import { Routes, Route, useLocation, Outlet } from 'react-router-dom'
import shortid from 'shortid'

import { Layout } from './components/app/layout/Layout'
import { Page404 } from './components/app/NotFound_404/Page404'
import { MainPage } from './components/app/mainPage/MainPage'

import { cabelSist } from './data/catalogData/cabel_sist'
import { cabProvProd } from './data/catalogData/cab_prov_prod'
import { Catalog } from './components/app/catalog/Catalog'

import { ArticlesPage } from './components/app/articles/Articles'
import { ArticlePage } from './components/app/articles/ArticlePage'

import { Layout as AdminLayout } from './components/admin/layout/Layout'
import { LoginPage } from './components/admin/loginPage/LoginPage'
import { ArticlesListPage } from './components/admin/articles/articlesList/ArticlesListPage'
import { EditArtPage } from './components/admin/articles/articlePage/EditArtPage'
import { CreateArtPage } from './components/admin/articles/articlePage/CreateArtPage'
import { Page404 as AdminPage404 } from './components/admin/NotFound_404/Page404'
import { WithAuthRedirectHOK } from './components/reusableElements/HOK/WithAuthRedirectHOK'

function App() {
   const location = useLocation()
   // console.log(location.pathname)
   // console.log(location)
   const [headerVariant, setHeaderVariant] = React.useState(location.pathname == '/' ? 1 : 2)
   const [hash, setHash] = React.useState(null)

   React.useEffect(() => {
      setHeaderVariant(location.pathname == '/' ? 1 : 2)
   }, [location.pathname])

   React.useEffect(() => {
      if (location.pathname == '/' && location.hash) setHash(location.hash)
      else setHash(null)
   }, [location.pathname, location.hash])

   //! Кабеленесущие системы
   const standartItems = {
      'Стандарт STRUT': {
         linkHere: cabelSist['Стандарт STRUT'].linkHere,
         cover: cabelSist['Стандарт STRUT'].cover,
      },
      'Стандарт LIGHT': {
         linkHere: cabelSist['Стандарт LIGHT'].linkHere,
         cover: cabelSist['Стандарт LIGHT'].cover,
      },
   }

   // наполняем items для кабеленесущих систем
   const level1_items_STRUT = {}
   const level1_items_LIGHT = {}
   const level2_items_STRUT = {}
   const lett_for_lev2_STRUT = []
   const level2_items_LIGHT = {}
   const lett_for_lev2_LIGHT = []

   Object.entries(cabelSist['Стандарт STRUT'].items).forEach(level1 => {
      lett_for_lev2_STRUT.push([level1[0], level1[1].linkHere])
      level1_items_STRUT[level1[0]] = {
         linkHere: level1[1].linkHere,
         cover: level1[1].cover,
      }
   })
   Object.entries(cabelSist['Стандарт LIGHT'].items).forEach(level1 => {
      lett_for_lev2_LIGHT.push([level1[0], level1[1].linkHere])
      level1_items_LIGHT[level1[0]] = {
         linkHere: level1[1].linkHere,
         cover: level1[1].cover,
      }
   })
   Object.entries(cabelSist['Стандарт STRUT'].items).forEach(level1 => {
      level2_items_STRUT[level1[1].linkHere] = level1[1].items
   })
   Object.entries(cabelSist['Стандарт LIGHT'].items).forEach(level1 => {
      level2_items_LIGHT[level1[1].linkHere] = level1[1].items
   })

   const prevLinks1 = {
      Главная: '/',
      Продукция: '/#production',
   }
   const prevLinks2 = {
      Главная: '/',
      Продукция: '/#production',
      'Кабеленесущие системы': '/catalog/cns',
   }
   const prevLinks3 = {
      Главная: '/',
      Продукция: '/#production',
      'Кабеленесущие системы': '/catalog/cns',
      'Стандарт STRUT': '/catalog/cns/strut',
   }
   const prevLinks4 = {
      Главная: '/',
      Продукция: '/#production',
      'Кабеленесущие системы': '/catalog/cns',
      'Стандарт LIGHT': '/catalog/cns/light',
   }

   //! Кабельно-проводниковая продукция
   const cppItems = {}
   const lett_for_cpp = []
   Object.entries(cabProvProd).forEach(level1 => {
      lett_for_cpp.push([level1[0], level1[1].linkHere])
      cppItems[level1[0]] = {
         linkHere: level1[1].linkHere,
         cover: level1[1].cover,
      }
   })
   const level1_items_cpp = {}
   Object.entries(cabProvProd).forEach(level1 => {
      level1_items_cpp[level1[1].linkHere] = level1[1].items
   })
   const prevLinks5 = {
      Главная: '/',
      Продукция: '/#production',
      'Кабельно-проводниковая продукция': '/catalog/cpp',
   }

   return (
      <>
         <Routes>
            <Route path='/' element={<Layout headerVariant={headerVariant} />}>
               <Route index element={<MainPage hash={hash} />} />

               <Route path='catalog/cns/*' element={<Outlet />}>
                  <Route
                     index
                     element={<Catalog type='links' items={standartItems} previousLinks={prevLinks1} lett={'Кабеленесущие системы'} />}
                  />

                  <Route
                     path={cabelSist['Стандарт STRUT'].linkHere}
                     element={<Catalog type='links' items={level1_items_STRUT} previousLinks={prevLinks2} lett={'Стандарт STRUT'} />}
                  />
                  {Object.entries(level2_items_STRUT).map((last, i) => (
                     <Route
                        key={shortid.generate()}
                        path={`${cabelSist['Стандарт STRUT'].linkHere}/${last[0]}`}
                        element={<Catalog type='modals' items={last[1]} lett={lett_for_lev2_STRUT[i][0]} previousLinks={prevLinks3} />}
                     />
                  ))}

                  <Route
                     path={cabelSist['Стандарт LIGHT'].linkHere}
                     element={<Catalog type='links' items={level1_items_LIGHT} previousLinks={prevLinks2} lett={'Стандарт LIGHT'} />}
                  />
                  {Object.entries(level2_items_LIGHT).map((last, i) => (
                     <Route
                        key={shortid.generate()}
                        path={`${cabelSist['Стандарт LIGHT'].linkHere}/${last[0]}`}
                        element={<Catalog type='modals' items={last[1]} lett={lett_for_lev2_LIGHT[i][0]} previousLinks={prevLinks4} />}
                     />
                  ))}
               </Route>

               <Route path='catalog/cpp/*' element={<Outlet />}>
                  <Route
                     index
                     element={
                        <Catalog type='links' items={cppItems} previousLinks={prevLinks1} lett={'Кабельно-проводниковая продукция'} />
                     }
                  />

                  {Object.entries(level1_items_cpp).map((last, i) => (
                     <Route
                        key={shortid.generate()}
                        path={last[0]}
                        element={<Catalog type='modals' items={last[1]} lett={lett_for_cpp[i][0]} previousLinks={prevLinks5} />}
                     />
                  ))}
               </Route>

               <Route path='news' element={<Outlet />}>
                  <Route index element={<ArticlesPage />} />
                  <Route path=':artId' element={<ArticlePage />} />
               </Route>
            </Route>

            <Route path='/login' element={<AdminLayout />}>
               <Route index element={<LoginPage />} />
            </Route>

            <Route path='admin' element={<AdminLayout />}>
               {/* <Route path='login' element={<LoginPage />} /> */}
               <Route
                  path='articles'
                  element={
                     <WithAuthRedirectHOK>
                        <ArticlesListPage />
                     </WithAuthRedirectHOK>
                  }
               />
               <Route
                  path='articles/:id'
                  element={
                     <WithAuthRedirectHOK>
                        <EditArtPage />
                     </WithAuthRedirectHOK>
                  }
               />
               <Route
                  path='articles/create'
                  element={
                     <WithAuthRedirectHOK>
                        <CreateArtPage />
                     </WithAuthRedirectHOK>
                  }
               />
               <Route
                  path='*'
                  element={
                     <WithAuthRedirectHOK>
                        <AdminPage404 />
                     </WithAuthRedirectHOK>
                  }
               />
            </Route>

            <Route path='*' element={<Layout headerVariant={headerVariant} />}>
               <Route path='*' element={<Page404 />} />
            </Route>
         </Routes>
      </>
   )
}

export default App
