import React from 'react'
import { Link as LinkRR } from 'react-router-dom'
import { ArticlePreview } from '../../articles/ArticlePreview'
import { ArrowBarRight } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import c from './newsBlock.module.scss'
import { articlesAppActs } from '../../../../store/reducers/articles/articlesAppSlice'
import { Preloader } from '../../../reusableElements/preloader/Preloader'
import { cutText } from '../../../../helpers/cutText'

const PAGE_NUM = 1
const PORTION_SIZE = 4

const NewsBlock = ({ hash }) => {
   const newsRef = React.useRef()
   if (hash == '#news') newsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', alignToTop: true })

   const dispatch = useDispatch()
   // dispatch(setArticlesPortionApp([{id:1, title: 'fffff'},{id:1, title: 'fffff'}])) сетаем в стор что-то

   React.useEffect(() => {
      dispatch(articlesAppActs.setCurrPage(PAGE_NUM))
      dispatch(articlesAppActs.setPortionSize(PORTION_SIZE))
      dispatch(articlesAppActs.setCustomOffset((PAGE_NUM - 1) * PORTION_SIZE))
   }, [])

   const {
      artsPortion,
      loadProg: { isLoadingArtsPortion },
      errs: { errOnGetArtsPortion },
   } = useSelector(state => state.articlesApp)

   return (
      <section className={`${c.articles} section dsection`} id='news' ref={newsRef}>
         <div className='articles__container'>
            <div className={c.titleWr}>
               <h2>Новости</h2>
               <LinkRR to='news?page=1'>
                  <span>Перейти в раздел</span>
                  <ArrowBarRight />
               </LinkRR>
            </div>
            <div className={c.articles}>
               {isLoadingArtsPortion ? (
                  <Preloader color={'#183864'} size={130} minHeight='355px' />
               ) : errOnGetArtsPortion ? (
                  <div className={c.lettWr}>
                     <span className={`${c.err} err`}>{cutText(errOnGetArtsPortion, 100)}</span>
                  </div>
               ) : !artsPortion || artsPortion.length === 0 ? (
                  <div className={c.lettWr}>
                     <span className={`${c.noArtsLett}`}>Раздел пуст</span>
                  </div>
               ) : (
                  artsPortion.map(a => <ArticlePreview key={a.id} title={a.title} cover={a.cover} date={a.date} link={a.id} />)
               )}
            </div>
         </div>
      </section>
   )
}

export { NewsBlock }
