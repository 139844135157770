import { createSlice, createAction } from '@reduxjs/toolkit'

/**
 * Получить порцию новостей
 * Получить 1 новость целиком
 *
 * Сделать 1 новость видимой-невидимой
 * Удалить 1 новость
 * Сделать ресорт порции новостей
 *
 *
 ** Поля в сторе:
 *
 ** articlesPortion
 ** articleFullView
 ** totalArtsCount
 ** currPage
 ** portionSize
 ** customOffset
 **
 ** isLoadingArticlesPortion
 ** isLoadingArticleFullView
 ** isLoadingArticleUpdate (загрузка обновленной информации для новости или создание новой новости) (храниться в формате: [id обновляющихся новостей])
 ** isLoadingArticleCreate (загрузка данных для создания новой новости)
 ** isLoadingArticleDelete (храниться в формате: [id удаляемых новостей])
 ** isLoadingArticlesResort
 **
 *? errs:
 ** errOnGetArticlesPortion
 ** errOnGetArticleFullView
 ** errOnUpdateArticle
 ** errOnCreateArticle
 ** errOnDeleteArticle
 ** errOnResortArticles
 *
 ** login
 ** errOnLogIn
 ** errOnCheckAuth
 *
 */

const initialState = {
   artsPortion: [],
   totalArtsCount: null,

   currPage: null,
   portionSize: null,
   customOffset: null,
   isPublished: 'all',

   artFullView: {},

   loadProg: {
      isLoadingArtsPortion: false,
      isLoadingArtFullView: false,
      isLoadingArtUpdate: [],
      isLoadingArtCreate: false,
      isLoadingArtDelete: [],
      isLoadingArtsResort: false,
   },

   errs: {
      errOnGetArtsPortion: null,
      errOnGetArtFullView: null,
      errOnArtUpdate: null,
      errOnArtCreate: null,
      errOnArtDelete: null,
      errOnArtsResort: null,
   },
}

const prefix = 'articlesAdmin'

export const SET_ARTICLES_CURR_PAGE_ADMIN = `${prefix}/SET_ARTICLES_CURR_PAGE_ADMIN`
export const SET_ARTICLES_PORTION_SIZE_ADMIN = `${prefix}/SET_ARTICLES_PORTION_SIZE_ADMIN`
export const SET_ARTICLES_CUSTOM_OFFSET_ADMIN = `${prefix}/SET_ARTICLES_CUSTOM_OFFSET_ADMIN`
export const RESORT_ARTICLES_ADMIN = `${prefix}/RESORT_ARTICLES_ADMIN`
export const START_ARTICLE_UPDATE_ADMIN = `${prefix}/START_ARTICLE_UPDATE_ADMIN`
export const START_ARTICLE_DELETE_ADMIN = `${prefix}/START_ARTICLE_DELETE_ADMIN`
export const GET_ARTICLE_FULL_VIEW_ADMIN = `${prefix}/GET_ARTICLE_FULL_VIEW_ADMIN`
export const START_ARTICLE_CREATE_ADMIN = `${prefix}/START_ARTICLE_CREATE_ADMIN`

// экшены c фиксированными константами, за которыми мы можем следить в сагах
const trackableActions = {
   setCurrPage: createAction(SET_ARTICLES_CURR_PAGE_ADMIN),
   setPortionSize: createAction(SET_ARTICLES_PORTION_SIZE_ADMIN),
   setCustomOffset: createAction(SET_ARTICLES_CUSTOM_OFFSET_ADMIN),
   resortArts: createAction(RESORT_ARTICLES_ADMIN),
   startArtUpdate: createAction(START_ARTICLE_UPDATE_ADMIN),
   startArtDelete: createAction(START_ARTICLE_DELETE_ADMIN),
   getFullView: createAction(GET_ARTICLE_FULL_VIEW_ADMIN),
   startArtCreate: createAction(START_ARTICLE_CREATE_ADMIN),
}

const articlesAdminSlice = createSlice({
   name: prefix,
   initialState,
   reducers: {
      setPortion: (state, action) => {
         state.artsPortion = action.payload
      },
      setTotalCount: (state, action) => {
         state.totalArtsCount = action.payload
      },
      setFullView: (state, action) => {
         state.artFullView = action.payload
      },
      setIsLoadingPortion: (state, action) => {
         state.loadProg.isLoadingArtsPortion = action.payload
      },
      setIsLoadingArtsResort: (state, action) => {
         state.loadProg.isLoadingArtsResort = action.payload
      },
      setIsLoadingArtCreate: (state, action) => {
         state.loadProg.isLoadingArtCreate = action.payload
      },
      setLoadingArtUpdate: (state, action) => {
         state.loadProg.isLoadingArtUpdate = [...state.loadProg.isLoadingArtUpdate, action.payload]
      },
      setEndLoadingArtUpdate: (state, action) => {
         state.loadProg.isLoadingArtUpdate = state.loadProg.isLoadingArtUpdate.filter(artId => artId !== action.payload)
      },
      setLoadingArtDelete: (state, action) => {
         state.loadProg.isLoadingArtDelete = [...state.loadProg.isLoadingArtDelete, action.payload]
      },
      setEndLoadingArtDelete: (state, action) => {
         state.loadProg.isLoadingArtDelete = state.loadProg.isLoadingArtDelete.filter(artId => artId !== action.payload)
      },
      setIsLoadingFullView: (state, action) => {
         state.loadProg.isLoadingArtFullView = action.payload
      },
      setErrOnGetPortion: (state, action) => {
         state.errs.errOnGetArtsPortion = action.payload
      },
      setErrOnArtsResort: (state, action) => {
         state.errs.errOnArtsResort = action.payload
      },
      setErrOnArtUpdate: (state, action) => {
         state.errs.errOnArtUpdate = action.payload
      },
      setErrOnArtCreate: (state, action) => {
         state.errs.errOnArtCreate = action.payload
      },
      setErrOnArtDelete: (state, action) => {
         state.errs.errOnArtDelete = action.payload
      },
      setErrOnGetFullView: (state, action) => {
         state.errs.errOnGetArtFullView = action.payload
      },
   },
   extraReducers: builder => {
      builder.addCase(trackableActions.setCurrPage, (state, action) => {
         state.currPage = action.payload
      })
      builder.addCase(trackableActions.setPortionSize, (state, action) => {
         state.portionSize = action.payload
      })
      builder.addCase(trackableActions.setCustomOffset, (state, action) => {
         state.customOffset = action.payload
      })
      // builder.addCase(trackableActions.resortArts, (state, action) => {
      //    state.customOffset = action.payload
      // })
      // builder.addCase(trackableActions.getFullView)
   },
})

export const articlesAdminReducer = articlesAdminSlice.reducer // в root-store
export const articlesAdminActs = { ...articlesAdminSlice.actions, ...trackableActions }
