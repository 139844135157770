import { all, fork } from 'redux-saga/effects'
import { sagaWatcherGetArticles } from './getArtsPortion'
import { sagaWatcherGetArtFullView } from './getArtFullView'
import { sagaWatcherResortArts } from './resortArts'
import { sagaWatcherUpdateArt } from './updateArt'
import { sagaWatcherDeleteArt } from './deleteArt'
import { sagaWatcherCreateArt } from './createArt'

export const articlesAdminSagaWatcher = function* () {
   yield all([
      fork(sagaWatcherGetArticles),
      fork(sagaWatcherGetArtFullView),
      fork(sagaWatcherResortArts),
      fork(sagaWatcherUpdateArt),
      fork(sagaWatcherDeleteArt),
      fork(sagaWatcherCreateArt),
   ])
}
