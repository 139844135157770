import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeft } from 'react-bootstrap-icons'
import { Button } from '../../../reusableElements/button/Button'
import { UpdateCreateForm } from './elements/updateCreateForm/UpdateCreateForm'
import c from './articleEditPage.module.scss'
import { articlesAdminActs } from '../../../../store/reducers/articles/articlesAdminSlice'

const emptyArt = {
   id: null,
   title: null,
   cover: null,
   date: null,
   content: null,
   published: 1,
   sort_num: null,
}
const CreateArtPage = () => {
   const dispatch = useDispatch()

   const {
      loadProg: { isLoadingArtCreate },
      errs: { errOnArtCreate },
   } = useSelector(state => state.articlesAdmin)

   React.useEffect(() => {
      dispatch(articlesAdminActs.setErrOnArtCreate(null)) // зануляем эту ошибку при первой загр страницы
      dispatch(articlesAdminActs.setFullView(emptyArt))
   }, [dispatch])

   const onSubmitFormHandler = artData => {
      dispatch(articlesAdminActs.startArtCreate(artData))
   }

   return (
      <div className={`section ${c.adminSection}`}>
         <div className={`adminArticle__container ${c.adminArticleWr}`}>
            <div className={c.titleBtnWr}>
               <Button
                  name='К списку новостей'
                  Icon={ArrowLeft}
                  extraClassName={c.goBackBtn}
                  title='Создать'
                  tag='link'
                  linkPath='/admin/articles'
                  isDisabled={false}
                  isLoading={false}
                  preloaderClr='#fff'
               />
               <h2 className={c.pageTitle}>Создать новость</h2>
            </div>
            <UpdateCreateForm
               initialData={emptyArt}
               isInProgressSubmit={isLoadingArtCreate}
               errOnSubmit={errOnArtCreate}
               onSubmitHandler={onSubmitFormHandler}
               haveResetBtn={true}
            />
         </div>
      </div>
   )
}

export { CreateArtPage }
