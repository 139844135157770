import { call, put, select, throttle } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import {
   SET_ARTICLES_CURR_PAGE_APP,
   SET_ARTICLES_PORTION_SIZE_APP,
   SET_ARTICLES_CUSTOM_OFFSET_APP,
   articlesAppActs,
} from '../../../reducers/articles/articlesAppSlice'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'

const handleGetArticles = function* (page, count, customOffset, isPublished) {
   yield put(articlesAppActs.setErrOnGetPortion(null))
   yield put(articlesAppActs.setIsLoadingPortion(true))

   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.getArticlesPortion, page, count, customOffset, isPublished)
      if (statusCode === 200) {
         yield put(articlesAppActs.setPortion(data.items))
         yield put(articlesAppActs.setTotalCount(data.totalCount))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAppActs.setErrOnGetPortion(getErrMessage(err)))
   }
   yield put(articlesAppActs.setIsLoadingPortion(false))
}

const sagaWorkerGetArticles = function* () {
   const { currPage, portionSize, customOffset, isPublished } = yield select(state => state.articlesApp)

   if (currPage !== null && portionSize !== null && customOffset !== null) {
      yield handleGetArticles(currPage, portionSize, customOffset, isPublished)
   }
}

export const sagaWatcherGetArticles = function* () {
   yield throttle(1500, SET_ARTICLES_CURR_PAGE_APP, sagaWorkerGetArticles)
   yield throttle(1500, SET_ARTICLES_PORTION_SIZE_APP, sagaWorkerGetArticles)
   yield throttle(1500, SET_ARTICLES_CUSTOM_OFFSET_APP, sagaWorkerGetArticles)
}
