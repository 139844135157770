import { call, put, select, takeLeading } from 'redux-saga/effects'
import { authFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr } from '../../handleErrs'
import { authActs, CHECK_AUTH } from '../../../reducers/articles/authAppSlice'

const sagaWorkerCheckAuth = function* (action) {
   const { login } = yield select(state => state.auth)

   if (!login) {
      yield put(authActs.setIsLoadingAuthCheck(true))
      try {
         const { statusCode, data, errors } = yield call(authFetchingAPI.checkAuth)
         if (statusCode === 200) {
            yield put(authActs.setLogin(data.login))
         } else {
            yield put(authActs.setLogin(null))
            throw new Error(joinErrsInStr(errors))
         }
      } catch (err) {}
      yield put(authActs.setIsLoadingAuthCheck(false))
   }
}

export const sagaWatcherCheckAuth = function* () {
   yield takeLeading(CHECK_AUTH, sagaWorkerCheckAuth)
}
