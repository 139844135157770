import c from './header.module.scss'

const Header = () => {
   return (
      <header className={c.header}>
         <div className='header__container'>
            <h1 className={c.title}>Панель управления «ЭКМ Трейд»</h1>
         </div>
      </header>
   )
}

export { Header }
