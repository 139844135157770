import { call, put, select, throttle } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'
import {
   articlesAdminActs,
   SET_ARTICLES_CURR_PAGE_ADMIN,
   SET_ARTICLES_CUSTOM_OFFSET_ADMIN,
   SET_ARTICLES_PORTION_SIZE_ADMIN,
} from '../../../reducers/articles/articlesAdminSlice'

const handleGetArticles = function* (page, count, customOffset, isPublished) {
   yield put(articlesAdminActs.setErrOnGetPortion(null))
   yield put(articlesAdminActs.setIsLoadingPortion(true))

   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.getArticlesPortion, page, count, customOffset, isPublished)
      if (statusCode === 200) {
         yield put(articlesAdminActs.setPortion(data.items))
         yield put(articlesAdminActs.setTotalCount(data.totalCount))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAdminActs.setErrOnGetPortion(getErrMessage(err)))
   }
   yield put(articlesAdminActs.setIsLoadingPortion(false))
}

const sagaWorkerGetArticles = function* () {
   const { currPage, portionSize, customOffset, isPublished } = yield select(state => state.articlesAdmin)
   if (currPage !== null && portionSize !== null && customOffset !== null) {
      yield handleGetArticles(currPage, portionSize, customOffset, isPublished)
   }
}

export const sagaWatcherGetArticles = function* () {
   
   yield throttle(1500, SET_ARTICLES_CURR_PAGE_ADMIN, sagaWorkerGetArticles)
   yield throttle(1500, SET_ARTICLES_PORTION_SIZE_ADMIN, sagaWorkerGetArticles)
   yield throttle(1500, SET_ARTICLES_CUSTOM_OFFSET_ADMIN, sagaWorkerGetArticles)
}
