import React from 'react'
import { Lock, BoxArrowInRight } from 'react-bootstrap-icons'
import { Button } from '../../reusableElements/button/Button'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import c from './login.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { authActs } from '../../../store/reducers/articles/authAppSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { Preloader } from '../../reusableElements/preloader/Preloader'

const DEFAULT_MAIN_ADMIN_PAGE = '/admin/articles'

const LoginPage = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const location = useLocation()
   const redirectTo = location?.state?.from?.pathname || DEFAULT_MAIN_ADMIN_PAGE

   const {
      login,
      loadProg: { isLoadingAuthCheck, isLoadingLogIn },
      errs: { errOnLogIn },
   } = useSelector(state => state.auth)

   React.useEffect(() => {
      dispatch(authActs.startCheckAuth())
   }, [dispatch])

   React.useEffect(() => {
      if (login) navigate(redirectTo)
   }, [login, navigate, redirectTo])

   const [loginValue, setLoginValue] = React.useState('')
   const [passwordValue, setPasswordValue] = React.useState('')
   const [isVisiblePassword, setIsVisiblePassword] = React.useState(false)

   const isValidLogin = !!loginValue
   const isValidPassword = !!passwordValue

   const onSubmitHandler = e => {
      e.preventDefault()
      dispatch(authActs.startLogIn({ login: loginValue, password: passwordValue }))
   }

   if (isLoadingAuthCheck) return <Preloader color={'#183864'} size={130} minHeight='90vh' />
   else
      return (
         <div className='login__container section'>
            <div className={c.loginWr}>
               <div className={c.loginTop}>
                  <Lock />
                  <h2 className={c.title}>Вход</h2>
               </div>
               <form className={c.inputsWr} onSubmit={onSubmitHandler}>
                  <div className={`${c.inputGroup} ${!isValidLogin ? c.withError : ''}`}>
                     <label htmlFor='login'>Логин</label>
                     <input type='text' id='login' value={loginValue} onInput={e => setLoginValue(e.currentTarget.value)} />
                     <span className={c.spanErr}> Поле обязательно для заполнения</span>
                  </div>

                  <div className={`${c.inputGroup} ${c.passwordInputGr} ${!isValidPassword ? c.withError : ''}`}>
                     <div className={c.eyeWr} onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
                        {isVisiblePassword ? <EyeSlashFill /> : <EyeFill />}
                     </div>

                     <label htmlFor='password'>Пароль</label>
                     <input
                        type={isVisiblePassword ? 'text' : 'password'}
                        id='password'
                        value={passwordValue}
                        onInput={e => setPasswordValue(e.currentTarget.value)}
                     />
                     <span className={c.spanErr}> Поле обязательно для заполнения</span>
                  </div>

                  <Button
                     type='submit'
                     name='Войти'
                     Icon={BoxArrowInRight}
                     extraClassName={c.loginBtn}
                     isLoading={isLoadingLogIn}
                     isDisabled={!isValidLogin || !isValidPassword}
                     preloaderClr='#fff'
                     onClickHandler={() => {}}
                  />

                  <div className={`err ${c.errOnLogIn}`}>{errOnLogIn}</div>
               </form>
            </div>
         </div>
      )
}

export { LoginPage }
