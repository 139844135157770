import { Link } from 'react-scroll'
import c from '../header.module.scss'
import { Link as LinkRR, useLocation } from 'react-router-dom'
import { useMatchMedia } from '../../../../../customHooks/useMatchMedia'
import { List, XLg } from 'react-bootstrap-icons'
import React from 'react'

const HeaderBottom = ({ variant }) => {
    const location = useLocation()
    const isNewsPath = location.pathname.match(/^\/news($|\/)/)
    const isCatalogPath = location.pathname.match(/^\/catalog\/\w+/)
    // console.log(isCatalogPath)

    const isLess768 = useMatchMedia().less768

    const [isOpenBurger, setIsOpenBurger] = React.useState(false)

    //    export const onOpenModal = () => {
    //     const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth + 'px'
    //     document.body.style.paddingRight = scrollbarWidth
    //     document.body.classList.add('lock')
    //  }

    //  export const onCloseModal = () => {
    //     document.body.style.paddingRight = '0px'
    //     document.body.classList.remove('lock')
    //  }

    const closeBurgHandler = () => {
        setIsOpenBurger(false)
        document.body.classList.remove('lock')
        document.body.style.paddingRight = '0px'
    }

    const openBurgHandler = () => {
        setIsOpenBurger(true)
        document.body.classList.add('lock')
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth + 'px'
        document.body.style.paddingRight = scrollbarWidth
    }

    const closeBurgHandlerForLink = () => {
        if (isLess768) closeBurgHandler()
    }

    if (variant === 1) {
        return (
            <div className={c.header_bottom}>
                <div className='header__container'>
                    {isLess768 && (
                        <div className={`${c.openBtn} ${isOpenBurger ? c.hide : ''}`} onClick={openBurgHandler}>
                            <List />
                        </div>
                    )}
                    <ul className={`${c.headerUl} ${!isOpenBurger ? c.hide : ''}`}>
                        {isLess768 && (
                            <div className={c.closeBtn} onClick={closeBurgHandler}>
                                <XLg />
                            </div>
                        )}
                        <li>
                            <Link
                                activeClass={c.active}
                                smooth={true}
                                duration={500}
                                spy={true}
                                to='aboutUs'
                                onClick={closeBurgHandlerForLink}
                            >
                                О компании
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass={c.active}
                                smooth={true}
                                duration={500}
                                spy={true}
                                to='production'
                                onClick={closeBurgHandlerForLink}
                            >
                                Продукция
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass={c.active}
                                smooth={true}
                                duration={500}
                                spy={true}
                                to='sertificates'
                                onClick={closeBurgHandlerForLink}
                            >
                                Сертификаты
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass={c.active}
                                smooth={true}
                                duration={500}
                                spy={true}
                                to='news'
                                onClick={closeBurgHandlerForLink}
                            >
                                Новости
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass={c.active}
                                smooth={true}
                                duration={500}
                                spy={true}
                                to='contacts'
                                onClick={closeBurgHandlerForLink}
                            >
                                Контакты
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    } else {
        return (
            <div className={c.header_bottom}>
                <div className='header__container'>
                    {isLess768 && (
                        <div className={`${c.openBtn} ${isOpenBurger ? c.hide : ''}`} onClick={openBurgHandler}>
                            <List />
                        </div>
                    )}
                    <ul className={`${c.headerUl} ${!isOpenBurger ? c.hide : ''}`}>
                        {isLess768 && (
                            <div className={c.closeBtn} onClick={closeBurgHandler}>
                                <XLg />
                            </div>
                        )}
                        <li>
                            <a href='/#aboutUs'>О компании</a>
                        </li>
                        <li>
                            <a href='/#production' className={isCatalogPath ? c.active : ''}>
                                Продукция
                            </a>
                        </li>
                        <li>
                            <a href='/#sertificates'>Сертификаты</a>
                        </li>
                        <li>
                            <LinkRR to='news' className={isNewsPath ? c.active : ''} onClick={closeBurgHandlerForLink}>
                                Новости
                            </LinkRR>
                        </li>
                        <li>
                            <a href='/#contacts'>Контакты</a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export { HeaderBottom }
