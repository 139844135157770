import { createSlice, createAction } from '@reduxjs/toolkit'

const initialState = {
   artsPortion: [],
   totalArtsCount: null,

   currPage: null,
   portionSize: null,
   customOffset: null,
   isPublished: 1,

   artFullView: {},

   loadProg: {
      isLoadingArtsPortion: false,
      isLoadingArtFullView: false,
   },

   errs: {
      errOnGetArtsPortion: null,
      errOnGetArtFullView: null,
   },
}

const prefix = 'articlesApp'

export const SET_ARTICLES_CURR_PAGE_APP = `${prefix}/SET_ARTICLES_CURR_PAGE_APP`
export const SET_ARTICLES_PORTION_SIZE_APP = `${prefix}/SET_ARTICLES_PORTION_SIZE_APP`
export const SET_ARTICLES_CUSTOM_OFFSET_APP = `${prefix}/SET_ARTICLES_CUSTOM_OFFSET_APP`
export const GET_ARTICLE_FULL_VIEW_APP = `${prefix}/GET_ARTICLE_FULL_VIEW_APP`

// экшены c фиксированными константами, за которыми мы можем следить в сагах
const trackableActions = {
   setCurrPage: createAction(SET_ARTICLES_CURR_PAGE_APP),
   setPortionSize: createAction(SET_ARTICLES_PORTION_SIZE_APP),
   setCustomOffset: createAction(SET_ARTICLES_CUSTOM_OFFSET_APP),
   getFullView: createAction(GET_ARTICLE_FULL_VIEW_APP),
}

const articlesAppSlice = createSlice({
   name: prefix,
   initialState,
   reducers: {
      setPortion: (state, action) => {
         state.artsPortion = action.payload
      },
      setTotalCount: (state, action) => {
         state.totalArtsCount = action.payload
      },
      setIsLoadingPortion: (state, action) => {
         state.loadProg.isLoadingArtsPortion = action.payload
      },
      setFullView: (state, action) => {
         state.artFullView = action.payload
      },
      setIsLoadingFullView: (state, action) => {
         state.loadProg.isLoadingArtFullView = action.payload
      },
      setErrOnGetPortion: (state, action) => {
         state.errs.errOnGetArtsPortion = action.payload
      },
      setErrOnGetFullView: (state, action) => {
         state.errs.errOnGetArtFullView = action.payload
      },
   },
   extraReducers: builder => {
      builder.addCase(trackableActions.setCurrPage, (state, action) => {
         state.currPage = action.payload
      })
      builder.addCase(trackableActions.setPortionSize, (state, action) => {
         state.portionSize = action.payload
      })
      builder.addCase(trackableActions.setCustomOffset, (state, action) => {
         state.customOffset = action.payload
      })
      // builder.addCase(trackableActions.getFullView)
   },
})

export const articlesAppReducer = articlesAppSlice.reducer // в root-store
export const articlesAppActs = { ...articlesAppSlice.actions, ...trackableActions }
