const getBlobFileName = file => {
   if (file.name) return file.name
   if (file.type) return 'image.' + file.type.split('/').at(-1)
   return null
}

// cover может быть null | string | Blob
const createArtFormData = function (artData) {
   const { title, cover, date, content, published } = artData

   const formData = new FormData()

   formData.append('title', title)
   formData.append('date', date)
   formData.append('content', content)
   formData.append('published', String(published))

   // cover может быть null | string | Blob
   // если null - удаляем существующее фото сотрудники и сотрудник теперь без фото
   // если string - значит у сотрудника уже есть cover и мы этот же путь до фото отправляем обратно
   // если Blob - загружаем новое фото
   if (cover === null) formData.append('cover', 'null')
   else if (typeof cover === 'string') formData.append('cover', cover)
   else if (typeof cover === 'object') formData.append('cover', cover, getBlobFileName(cover))
   return formData
}

// null | string | Blob
const createImgFormData = function (img) {
   const formData = new FormData()

   // img может быть null | string | Blob
   // если null - удаляем существующее фото сотрудники и сотрудник теперь без фото
   // если string - значит у сотрудника уже есть img и мы этот же путь до фото отправляем обратно
   // если Blob - загружаем новое фото
   if (img === null) formData.append('file', 'null')
   else if (typeof img === 'string') formData.append('file', img)
   else if (typeof img === 'object') formData.append('file', img, getBlobFileName(img))

   return formData
}

export { createArtFormData, createImgFormData }
