import { all, fork } from 'redux-saga/effects'
import { articlesAppSagaWatcher } from './app/articles'
import { articlesAdminSagaWatcher } from './admin/articles'
import { authSagaWatcher } from './admin/auth'

const rootSaga = function* () {
   yield all([fork(articlesAppSagaWatcher), fork(articlesAdminSagaWatcher), fork(authSagaWatcher)])
}

export { rootSaga }
