import { call, put, select, takeLeading } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'
import { articlesAdminActs, RESORT_ARTICLES_ADMIN } from '../../../reducers/articles/articlesAdminSlice'

const sagaWorkerResortArts = function* (action) {
   const resortedArts = action.payload.map(a => ({ id: a.id, sort_num: a.sort_num }))
   yield put(articlesAdminActs.setErrOnArtsResort(null))
   yield put(articlesAdminActs.setIsLoadingArtsResort(true))

   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.resortArticles, resortedArts)

      if (statusCode === 200) {
         const resortedArtsFromResp = {}
         data.items.forEach(a => (resortedArtsFromResp[a.id] = a.sort_num))

         const { artsPortion } = yield select(state => state.articlesAdmin)
         const artsPortionCopy = artsPortion
            .map(a => ({ ...a, sort_num: resortedArtsFromResp[a.id] }))
            .sort((a_1, a_2) => a_2.sort_num - a_1.sort_num)
         yield put(articlesAdminActs.setPortion(artsPortionCopy))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAdminActs.setErrOnArtsResort(getErrMessage(err)))
   }
   yield put(articlesAdminActs.setIsLoadingArtsResort(false))
}

export const sagaWatcherResortArts = function* () {
   yield takeLeading(RESORT_ARTICLES_ADMIN, sagaWorkerResortArts)
}
