import { call, put, takeLeading } from 'redux-saga/effects'
import { authFetchingAPI } from '../../../DAL/fetchingAPI'
import { getErrMessage, joinErrsInStr } from '../../handleErrs'
import { authActs, START_LOGIN } from '../../../reducers/articles/authAppSlice'

const sagaWorkerLogin = function* (action) {
   const { login, password } = action.payload

   yield put(authActs.setErrOnLogIn(null))
   yield put(authActs.setIsLoadingLogIn(true))
   try {
      const { statusCode, data, errors } = yield call(authFetchingAPI.login, login, password)
      if (statusCode === 200) {
         yield put(authActs.setLogin(data.login))
      } else {
         yield put(authActs.setLogin(null))
         throw new Error(joinErrsInStr(errors))
      }
   } catch (err) {
      yield put(authActs.setErrOnLogIn(getErrMessage(err)))
   }
   yield put(authActs.setIsLoadingLogIn(false))
}

export const sagaWatcherLogin = function* () {
   yield takeLeading(START_LOGIN, sagaWorkerLogin)
}
