import { call, put, select, takeLeading } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { GET_ARTICLE_FULL_VIEW_APP, articlesAppActs } from '../../../reducers/articles/articlesAppSlice'
import { getErrMessage, joinErrsInStr } from '../../handleErrs'

const sagaWorkerGetArtFullView = function* (action) {
   const artId = action.payload
   yield put(articlesAppActs.setErrOnGetFullView(null))
   yield put(articlesAppActs.setIsLoadingFullView(true))

   try {
      const listOfAlreadyLoadedArts = yield select(state => state.articlesApp.artsPortion)
      const desiredArt = listOfAlreadyLoadedArts?.find(art => art.id === artId)

      if (desiredArt) yield put(articlesAppActs.setFullView(desiredArt))
      else {
         const { statusCode, data, errors } = yield call(articlesFetchingAPI.getOneArticle, artId)
         if (statusCode === 200) {
            yield put(articlesAppActs.setFullView(data))
         } else throw new Error(joinErrsInStr(errors))
      }
   } catch (err) {
      yield put(articlesAppActs.setErrOnGetFullView(getErrMessage(err)))
   }
   yield put(articlesAppActs.setIsLoadingFullView(false))
}

export const sagaWatcherGetArtFullView = function* () {
   yield takeLeading(GET_ARTICLE_FULL_VIEW_APP, sagaWorkerGetArtFullView)
}
