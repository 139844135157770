import { ArrowBarRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import nophoto from '../../../assets/images/common/nophoto.png'

// import img from '../../assets/images/catalog/kabelno_prov_prod/kupe/1.webp'

import c from './catalog.module.scss'


const CatalogCard = ({ type, info, onClickFunc }) => {
    // type = links / modals

    if (type == 'link') {
        return (
            <Link to={info.linkHere} className={`${c.catalogCard} effect_on_hover`}>
                <div className={c.coverWr}>
                    <img src={info.cover ? info.cover : nophoto} />
                </div>
                <div className={c.name}>
                    {info.name}
                </div>
                <ArrowBarRight />
            </Link>
        )
    } else return (
        <div onClick={() => { onClickFunc(info) }} className={`${c.catalogCard} effect_on_hover`}>
            <div className={c.coverWr}>
                <img src={info.cover ? info.cover : nophoto} />
            </div>
            <div className={c.name}>
                {info.name}
            </div>
        </div>
    )

}

export { CatalogCard }
