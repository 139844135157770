import { Button } from '../../../reusableElements/button/Button'
import { EyeFill, EyeSlashFill, GripHorizontal, PencilSquare, Trash, ArrowDownUp } from 'react-bootstrap-icons'
import { cutText } from '../../../../helpers/cutText'
import c from './articlesList.module.scss'

const ArticlePreview = ({
   artData,
   onClickDeleteBtnHandler,
   onClickChangePublBtnHandler,
   isInProgressChangePubl,
   isInProgressDeleting,
}) => {
   const { id, title, published } = artData

   return (
      <>
         <div className={c.dragEl} title='Поменять порядок'>
            <GripHorizontal />
         </div>
         <div className={c.title}>
            <p>{cutText(title, 85)}</p>
         </div>
         <div className={c.published}>
            <Button
               type='submit'
               name={null}
               Icon={published ? EyeFill : EyeSlashFill}
               extraClassName={`${published ? c.publOnBtn : c.publOffBtn}`}
               title={published ? 'Скрыть' : 'Опубликовать'}
               isLoading={isInProgressChangePubl}
               preloaderClr='#fff'
               onClickHandler={e => onClickChangePublBtnHandler({ ...artData, published: artData.published === 0 ? 1 : 0 })}
            />
         </div>
         <div className={c.edit}>
            <Button
               type='button'
               name={null}
               Icon={PencilSquare}
               extraClassName={c.editBtn}
               title='Редактировать'
               tag='link'
               linkPath={`${id}`}
               preloaderClr='#fff'
               onClickHandler={() => {}}
            />
         </div>
         <div className={c.trash}>
            <Button
               type='submit'
               name={null}
               Icon={Trash}
               extraClassName={c.trashBtn}
               title='Удалить'
               isLoading={isInProgressDeleting}
               preloaderClr='#fff'
               onClickHandler={e => onClickDeleteBtnHandler({ title, id })}
            />
         </div>
      </>
   )
}

export { ArticlePreview }
