import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { authActs } from '../../../store/reducers/articles/authAppSlice'
import { Preloader } from '../preloader/Preloader'

/**
 * Заходи на страницу, которая требует авторизованности
 * Если в стейте нет авторизованности, то отправляем запрос на проверку авторизованности
 * Пока нет результата по этому запросу - показываем прелоадер
 *
 */
const WithAuthRedirectHOK = function ({ children }) {
   const dispatch = useDispatch()
   const location = useLocation()

   const {
      login,
      loadProg: { isLoadingAuthCheck },
   } = useSelector(state => state.auth)

   const isAuth = !!login

   React.useEffect(() => {
      dispatch(authActs.startCheckAuth())
   }, [dispatch])

   if (!isAuth && isLoadingAuthCheck) return <Preloader color={'#183864'} size={130} minHeight='90vh' />
   if (!isAuth && !isLoadingAuthCheck) return <Navigate to='/login' state={{ from: location }} />
   return children
}

export { WithAuthRedirectHOK }

// const WithAuthRedirectHOK = function ({ children }) {
//    const location = useLocation()

//    const {
//       login,
//       loadProg: { isLoadingAuthCheck },
//    } = useSelector(state => state.auth)

//    const isAuth = !!login

//    React.useEffect(() => {
//       if (!isAuth) dispatch(authActs.startCheckAuth())
//    }, [])

//    if (!isAuth) return <Navigate to='/login' state={{ from: location }} />
//    return children
// }
