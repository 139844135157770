import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { textEditorFetchingAPI } from '../../../../../../store/DAL/fetchingAPI'
import { getErrMessage, joinErrsInStr } from '../../../../../../store/sagas/handleErrs'
import { cutText } from '../../../../../../helpers/cutText'

{
   /* Вот тут про загрузку изображений в текстовый редактор
     https://www.tiny.cloud/docs/tinymce/latest/image/#interactive-example
     https://www.tiny.cloud/docs/tinymce/latest/file-image-upload/#images_upload_url 
     https://www.tiny.cloud/docs/tinymce/latest/react-ref/#using-the-tinymce-react-component-as-a-uncontrolled-component
      */
}

const API_KEY = 'v7crlefcdum3bxbylgrihg530ru4n5ku1smo4e2e9ywrbrbs'

const settings = {
   placeholder: 'Текст новости',
   height: 500,
   menubar: true,
   branding: false,
   language: 'ru',

   plugins: 'autolink lists link image preview searchreplace fullscreen insertdatetime table code wordcount',
   toolbar:
      'undo redo blocks fontsize bold italic underline strikethrough align numlist ' +
      'bullist lineheight outdent indent forecolor backcolor removeformat table link image',
   content_style: `body { font-family: Helvetica, Tahoma, sans-serif; line-height: 1.4; color: #183864;} `,
   paste_block_drop: true,

   relative_urls: false,
   remove_script_host: false,

   file_picker_types: 'image',
   images_file_types: 'jpeg,jpg,png,webp',
   image_title: true,
   images_reuse_filename: true,
   automatic_uploads: false,
   document_base_url: process.env.REACT_APP_BASE_PATH_FOR_IMAGES,
   images_upload_base_path: process.env.REACT_APP_BASE_PATH_FOR_IMAGES,

   images_upload_handler: (blobInfo, progressCallback) =>
      new Promise(async (resolve, reject) => {
         try {
            const { statusCode, data, errors } = await textEditorFetchingAPI.saveImg(blobInfo.blob(), progressCallback)
            if (statusCode === 200) return resolve(data.path)
            else throw new Error(joinErrsInStr(errors))
         } catch (err) {
            reject({ message: cutText(getErrMessage(err), 150), remove: true })
         }
      }),
}

const TextEditor = ({ initialValue, editorRef, editorId, editorLabel }) => {
   return (
      <>
         <label htmlFor={editorId}>{editorLabel}</label>
         <Editor
            id={editorId}
            apiKey={API_KEY}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue={initialValue || ''}
            init={settings}
         />
      </>
   )
}

export { TextEditor }
