import { call, put, select, takeLeading } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'
import { articlesAdminActs, START_ARTICLE_CREATE_ADMIN, START_ARTICLE_UPDATE_ADMIN } from '../../../reducers/articles/articlesAdminSlice'

const sagaWorkerCreateArt = function* (action) {
   const art = action.payload

   yield put(articlesAdminActs.setErrOnArtCreate(null))
   yield put(articlesAdminActs.setIsLoadingArtCreate(true))
   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.createArticle, art)
      if (statusCode === 200) {
         yield put(articlesAdminActs.setFullView(data))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAdminActs.setErrOnArtCreate(getErrMessage(err)))
   }
   yield put(articlesAdminActs.setIsLoadingArtCreate(false))
}

export const sagaWatcherCreateArt = function* () {
   yield takeLeading(START_ARTICLE_CREATE_ADMIN, sagaWorkerCreateArt)
}
