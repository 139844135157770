import { Contacts } from './contacts/Contacts'
import { About } from './aboutUs/About'
import { Banner } from './banner/Banner'
import { ProductsMain } from './products/ProductsMain'
import { Sertificates } from './sertificates/Sertificates'
import { NewsBlock } from './news/NewsBlock'

const MainPage = ({ hash }) => {
   return (
      <>
         <Banner />
         <About hash={hash} />
         <ProductsMain hash={hash} />
         <Sertificates hash={hash} />
         <NewsBlock hash={hash} />
         <Contacts hash={hash} />
      </>
   )
}

export { MainPage }
