import React from 'react'
import { XLg } from 'react-bootstrap-icons'
import { Button } from '../button/Button'
import c from './modalOnDelete.module.scss'
import { cutText } from '../../../helpers/cutText'
const ModalOnDelete = ({
   isOpen,
   closeModalHandler,
   deleteItemHandler,
   lettPartBefore,
   lettPartItemInfo,
   lettPartAfter,
   isDelettingInProgress,
   errOnDelete,
   isAlreadyDeleted,
}) => {
   const handleCloseModalWrapper = function (e) {
      const classListArr = Array.from(e?.target?.classList || [])
      if (classListArr.includes('close') && e.button === 0) closeModalHandler()
   }

   React.useEffect(() => {
      if (isAlreadyDeleted) closeModalHandler()
   }, [isAlreadyDeleted])

   return (
      <div className={`${c.modalWr}  ${isOpen ? c.visible : ''} close`} onClick={handleCloseModalWrapper}>
         <div className={c.modalInner}>
            <div className={`${c.closeBtn} close`} onClick={closeModalHandler}>
               <XLg />
               <div className={`${c.closeBtnMask} close`}></div>
            </div>
            <div className={c.lett}>
               {lettPartBefore}
               <span>{lettPartItemInfo}</span>
               {lettPartAfter}
            </div>
            <div className={c.btns}>
               <Button
                  type='button'
                  name='Да'
                  Icon={null}
                  isDisabled={false}
                  extraClassName={c.yesBtn}
                  isLoading={isDelettingInProgress}
                  preloaderClr='#fff'
                  onClickHandler={deleteItemHandler}
               />

               <Button
                  type='button'
                  name='Нет'
                  Icon={null}
                  isDisabled={false}
                  extraClassName={c.noBtn}
                  isLoading={false}
                  preloaderClr='#fff'
                  onClickHandler={() => closeModalHandler()}
               />
            </div>
            <p className={c.err}>{cutText(errOnDelete || '', 50)}</p>
         </div>
      </div>
   )
}

export { ModalOnDelete }
