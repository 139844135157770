import React from 'react'
import { XLg } from 'react-bootstrap-icons'
import { ImageFill } from 'react-bootstrap-icons'
import { getPhotoPath } from '../../../../../../helpers/getPhotoPath'
import { CropCoverModal } from '../cropCoverModal/CropCoverModal'
import { Button } from '../../../../../reusableElements/button/Button'
import { onOpenModal, onCloseModal } from '../../../../../../helpers/forOpenModalOverflowHandler'
import c from './coverBlock.module.scss'

// type AvatarBlockPropsType = {
//     photoValue: string | null | Blob
//     onChangePhotoValueHandler: (newValue: string | null | Blob) => void
// }

const MAX_WIDTH = 1000
const MAX_HEIGHT = 620

// if (width > height) {
//    if (width > MAX_WIDTH) {
//       height *= MAX_WIDTH / width
//       width = MAX_WIDTH
//    }
// } else {
//    if (height > MAX_HEIGHT) {
//       width *= MAX_HEIGHT / height
//       height = MAX_HEIGHT
//    }
// }

const CoverBlock = ({ photoValue, onChangePhotoValueHandler }) => {
   const [imgSrc, setImgSrc] = React.useState('')
   const [crop, setCrop] = React.useState()

   const [isOpenModalCrop, setIsOpenModalCrop] = React.useState(false)

   const handleOpenModal = function () {
      setIsOpenModalCrop(true)
      onOpenModal()
   }

   const handleCloseModal = function () {
      setIsOpenModalCrop(false)
      onCloseModal()
   }

   const fileInputRef = React.useRef(null)
   const invisibleCanvasRef = React.useRef(null)

   const onUploadPhotoFile = function (e) {

      const canvas = invisibleCanvasRef.current

      if (e.target.files && e.target.files.length > 0 && canvas) {
         setCrop(undefined) // Makes crop preview update between images.

         const tmpImg = new Image()
         tmpImg.src = URL.createObjectURL(e.target.files[0])
         tmpImg.addEventListener('load', () => {
            let width = tmpImg.width
            let height = tmpImg.height

            if (width > height) {
               if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
               }
            } else {
               if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
               }
            }

            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(tmpImg, 0, 0, width, height)
            const dataurl = canvas.toDataURL('image/webp')
            setImgSrc(dataurl.toString() || '')
         
         })

         // setCrop(undefined) // Makes crop preview update between images.
         // const reader = new FileReader()
         // reader.addEventListener('load', () => {
         //    console.log('reader.result ', reader.result)
         //    setImgSrc(reader.result?.toString() || '')
         // })
         // reader.readAsDataURL(e.target.files[0])
         handleOpenModal()
      }
   }

   // React.useEffect(()=>{console.log(imgSrc)},[imgSrc])

   return (
      <>
         <div className={c.photoWr}>
            <canvas className={c.invisCanvasToResizeImg} ref={invisibleCanvasRef}></canvas>
            <img src={getPhotoPath(photoValue)} alt='cover' />
            {photoValue && (
               <Button
                  type='button'
                  name={null}
                  Icon={XLg}
                  extraClassName={c.deletePhotoBtn}
                  isDisabled={false}
                  isLoading={false}
                  title='Удалить фото'
                  onClickHandler={() => onChangePhotoValueHandler(null)}
               />
            )}
            {/* <Button type='button' name={null} Icon={RiImageAddFill} extraClassName={c.changePhotoBtn} isDisabled={false} isLoading={false} title='Изменить фото' /> */}

            <label className={c.changePhotoBtn}>
               <ImageFill />
               <input
                  hidden
                  accept='image/jpeg,image/jpg,image/png,image/webp'
                  type='file'
                  onInput={onUploadPhotoFile}
                  ref={fileInputRef}
               />
            </label>
         </div>
         <CropCoverModal
            isOpen={isOpenModalCrop}
            handleCloseModal={handleCloseModal}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
            crop={crop}
            setCrop={setCrop}
            onChangePhotoValueHandler={onChangePhotoValueHandler}
            fileInputRef={fileInputRef}
         />
      </>
   )
}

export { CoverBlock }
