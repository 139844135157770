import React from 'react'
import shortid from 'shortid'
import { EyeFill } from 'react-bootstrap-icons'

import c from './sertificates.module.scss'

import sert1_img from '../../../../assets/images/sertificates_preview/sert1.webp'
import sert2_img from '../../../../assets/images/sertificates_preview/sert2.webp'
import sert3_img from '../../../../assets/images/sertificates_preview/sert3.webp'
import sert1_file from '../../../../assets/files/sert1.pdf'
import sert2_file from '../../../../assets/files/sert2.pdf'
import sert3_file from '../../../../assets/files/sert3.pdf'



const sertificatesData = [
    {
        prev: sert1_img,
        link: sert1_file
    },
    {
        prev: sert2_img,
        link: sert2_file
    },
    {
        prev: sert3_img,
        link: sert3_file
    },
]

const SertificateItem = ({ prev, link }) => {
    return (
        <a href={link} className={`${c.sert} effect_on_hover`} target='_blank'>
            <div className={c.imgWr}>
                <img src={prev} alt='Сертификат' />
            </div>
            <div className={c.mask}>
                <EyeFill />
                <p>Смотреть полностью</p>
            </div>
        </a>
    )
}

const Sertificates = ({hash}) => {
    const sertRef = React.useRef()
    if (hash == '#sertificates') {
        sertRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
    }
    return (
        <section className={`${c.sertificates} section`} id='sertificates' ref={sertRef}>
            <div className='sert__container'>
                <h2>Сертификаты</h2>
                <div className={c.sertificates}>
                    {sertificatesData.map(el => <SertificateItem key={shortid.generate()} prev={el.prev} link={el.link}/>)}
                </div>
            </div>
        </section>
    )
}

export { Sertificates }