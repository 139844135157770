import { createSlice, createAction } from '@reduxjs/toolkit'

const initialState = {
   login: null,

   loadProg: {
      isLoadingAuthCheck: false,
      isLoadingLogIn: false,
   },

   errs: { errOnLogIn: null },
}

/**
 * на всех страницах админки проверяем залогиненность и, если ее нет, то редиректит на страницу логина
 */

const prefix = 'auth'

export const CHECK_AUTH = `${prefix}/CHECK_AUTH`
export const START_LOGIN = `${prefix}/START_LOGIN`

// экшены c фиксированными константами, за которыми мы можем следить в сагах
const trackableActions = {
   startCheckAuth: createAction(CHECK_AUTH),
   startLogIn: createAction(START_LOGIN),
}

const authSlice = createSlice({
   name: prefix,
   initialState,
   reducers: {
      setLogin: (state, action) => {
         state.login = action.payload
      },
      setIsLoadingAuthCheck: (state, action) => {
         state.loadProg.isLoadingAuthCheck = action.payload
      },
      setIsLoadingLogIn: (state, action) => {
         state.loadProg.isLoadingLogIn = action.payload
      },
      setErrOnLogIn: (state, action) => {
         state.errs.errOnLogIn = action.payload
      },
   },
})

export const authReducer = authSlice.reducer // в root-store
export const authActs = { ...authSlice.actions, ...trackableActions }
