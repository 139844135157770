import { ArrowBarRight } from 'react-bootstrap-icons'
import { Link as LinkRR } from 'react-router-dom'

import c from './articles.module.scss'
import { getPhotoPath } from '../../../helpers/getPhotoPath'

const ArticlePreview = ({ title, cover, date, link }) => {
   return (
      <LinkRR to={`/news/${link}`} className={`${c.article} effect_on_hover`}>
         <div className={c.imgWr}>
            <img src={getPhotoPath(cover)} alt={title} />
         </div>
         <div className={c.artText}>
            <p className={c.date}>{date}</p>
            <h3 className={c.artName}>{title}</h3>
         </div>
         <ArrowBarRight />
      </LinkRR>
   )
}

export { ArticlePreview }
