import { SvgPreloaderImg } from './SvgPreloaderImg'
import c from './preloader.module.scss'

// type PreloaderPropsType = {
//    color: string
//    size: number
//    minHeight: string
//    text?: string
// }
const Preloader = ({ color, size, minHeight, text }) => {
   return (
      <div className={c.preloaderBlock} style={{ minHeight: minHeight }}>
         <div
            className={c.svgWr}
            style={{
               width: `${size}px`,
               height: `${size}px`,
            }}>
            <SvgPreloaderImg color={color} />
         </div>
         {text ? <span style={{ color: color }}>{text}</span> : <></>}
      </div>
   )
}

export { Preloader }
