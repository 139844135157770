export const joinErrsInStr = function (errsArr, defaultErrValue = 'Some unknown error') {
   errsArr = errsArr.map(err => err.trim()).filter(err => !!err)

   if (!errsArr.length) return defaultErrValue

   errsArr = errsArr.map((err, idx) => {
      if (idx === 0) return err[0].toUpperCase() + err.slice(1)
      return err[0].toLowerCase() + err.slice(1)
   })

   return errsArr.join('; ').trim().replace(/[;,]$/g, '.')
}

export const getErrMessage = function (err) {
   if (err.response?.data?.errors) return joinErrsInStr(err.response?.data?.errors)
   return err.message
}
