import React from 'react'
import { XLg } from 'react-bootstrap-icons'
import ReactCrop from 'react-image-crop'
import { centerAspectCrop } from './assets/centerAspectCrop'
import { useDebounceEffect } from './assets/useDebounceEffect'
import { canvasPreview } from './assets/canvasPreview'
import 'react-image-crop/src/ReactCrop.scss'
import { Upload } from 'react-bootstrap-icons'
import { Button } from '../../../../../reusableElements/button/Button'
import c from './cropCoverModal.module.scss'

// type CropAvatarModalPropsType = {
//    isOpen: boolean
//    handleCloseModal: () => void
//    imgSrc: string
//    setImgSrc: React.Dispatch<SetStateAction<string>>
//    crop: Crop | undefined
//    setCrop: React.Dispatch<SetStateAction<Crop | undefined>>
//    onChangePhotoValueHandler: (newValue: string | null | Blob) => void
//    fileInputRef: React.RefObject<HTMLInputElement>
// }

const ASPECT_RATIO = 1000 / 620

const CropCoverModal = ({ isOpen, handleCloseModal, imgSrc, crop, setCrop, onChangePhotoValueHandler, fileInputRef }) => {
   const previewCanvasRefBig = React.useRef(null)
   // const previewCanvasRefSmall = React.useRef(null)
   const imgRef = React.useRef(null)
   const [completedCrop, setCompletedCrop] = React.useState()

   // функция, срабатывающая, кода в image для модалки подгрузилась
   function onImageLoad(e) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, ASPECT_RATIO))
   }

   useDebounceEffect(
      async () => {
         if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRefBig.current
            // && previewCanvasRefSmall.current
         ) {
            canvasPreview(imgRef.current, previewCanvasRefBig.current, completedCrop, 1, 0)
            // canvasPreview(imgRef.current, previewCanvasRefSmall.current, completedCrop, 1, 0)
         }
      },
      100,
      [completedCrop]
   )

   const onUpdatePhoto = function () {
      // if (!previewCanvasRefBig.current) throw new Error('Crop canvas does not exist')
      if (previewCanvasRefBig.current) {
         previewCanvasRefBig.current.toBlob(blob => {
            // if (!blob) throw new Error('Failed to create blob')
            if (blob) onChangePhotoValueHandler(blob)
            handleCloseModal()
            if (fileInputRef.current) fileInputRef.current.value = ''
         })
      }
   }

   const handleCloseWrapper = e => {
      const classListArr = Array.from(e?.target?.classList || [])
      if (classListArr.includes('close') && e.button === 0) handleCloseModal()
   }

   return (
      <div className={`${c.cropAvatarModal} ${isOpen ? c.visible : ''} close`} onClick={handleCloseWrapper}>
         <div className={c.modalContent}>
            <p className={c.title}>Обрезка изображения (выберите область)</p>
            <div className={`${c.closeBtn} close`} onClick={handleCloseModal}>
               <XLg />
               <div className={`${c.closeBtnMask} close`}></div>
            </div>
            {!!imgSrc && (
               <div className={c.cropWr}>
                  <ReactCrop
                     crop={crop}
                     onChange={(_, percentCrop) => setCrop(percentCrop)}
                     onComplete={c => setCompletedCrop(c)}
                     aspect={ASPECT_RATIO}
                     className={c.cropBlock}>
                     <img ref={imgRef} alt='Crop me' src={imgSrc} onLoad={onImageLoad} />
                  </ReactCrop>
               </div>
            )}

            {!!completedCrop && (
               <>
                  {/* <p className={c.lett}>Предпросмотр:</p>
                  <div className={c.thumbs}> */}
                  <canvas className={c.canvasBig} ref={previewCanvasRefBig} />
                  {/* <canvas className={c.canvasSmall} ref={previewCanvasRefSmall} /> */}
                  {/* </div> */}
                  <Button
                     type='button'
                     name='Обновить'
                     extraClassName={c.updatePhotoBtn}
                     isDisabled={false}
                     isLoading={false}
                     Icon={Upload}
                     onClickHandler={onUpdatePhoto}
                  />
               </>
            )}
         </div>
      </div>
   )
}

export { CropCoverModal }
