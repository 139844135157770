import { call, put, select, takeLeading } from 'redux-saga/effects'
import { articlesFetchingAPI } from '../../../DAL/fetchingAPI'
import { joinErrsInStr, getErrMessage } from '../../handleErrs'
import { articlesAdminActs, START_ARTICLE_DELETE_ADMIN } from '../../../reducers/articles/articlesAdminSlice'

const sagaWorkerDeleteArt = function* (action) {
   const artId = action.payload

   yield put(articlesAdminActs.setErrOnArtDelete(null))
   yield put(articlesAdminActs.setLoadingArtDelete(artId))
   try {
      const { statusCode, data, errors } = yield call(articlesFetchingAPI.deleteArticle, artId)
      if (statusCode === 200) {
         const { artsPortion } = yield select(state => state.articlesAdmin)
         yield put(articlesAdminActs.setPortion(artsPortion.filter(a => a.id !== artId)))
      } else throw new Error(joinErrsInStr(errors))
   } catch (err) {
      yield put(articlesAdminActs.setErrOnArtDelete(getErrMessage(err)))
   }
   yield put(articlesAdminActs.setEndLoadingArtDelete(artId))
}

export const sagaWatcherDeleteArt = function* () {
   yield takeLeading(START_ARTICLE_DELETE_ADMIN, sagaWorkerDeleteArt)
}
